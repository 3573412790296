import * as React from "react"
import '../css/main.css'
import '../css/bootstrap.min.css'
import '../css/animate.compat.css'
import '../css/boxicons.min.css'
import '../css/icofont.min.css'
import '../css/owl.carousel.min.css'

import Head from '../components/head'
import HeaderComponent from "../components/header.js"
import useScript from "../components/useScript"
import FooterComponent from "../components/footer"

// markup
const FaqPage = () => {
  
    useScript('static/js/jquery.min.js')
    useScript('static/js/bootstrap.bundle.js')
    useScript('static/js/jquery.easing.min.js')
    useScript('static/js/venobox.min.js')
    useScript('static/js/jquery.waypoints.min.js')
    useScript('static/js/counterup.min.js')
    useScript('static/js/owl.carousel.min.js')
    useScript('static/js/isotope.pkgd.min.js')
    useScript('static/js/aos.js')
    useScript('static/js/main.js')

  return (
    <>
      <Head title={'FAQ'} />
      <header id="header" className="fixed-top">
        <HeaderComponent />
      </header>
      <main id='main'>
        <section className="breadcrumbs">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                <h2>FAQ</h2>
                <ol>
                    <li><a href="/">Home</a></li>
                    <li>FAQ</li>
                </ol>
                </div>
            </div>
        </section>
        <section className="why-us" data-aos="fade-up">
            <div className="container">
                <div className="row">
                <div className="col-lg d-flex flex-column justify-content-center px-5 py-3">
                    <div className="icon-box">
                    <div className="icon"><i className="bx bx-message-rounded-detail"></i></div>
                    <h4 className="title">How does the investment and buying process work?</h4>
                    <p className="description">You can browse in Claim Market different projects and buy in the most appealing to you acquiring the respective claims. Alternatively, you can look for the desired <u>Effective Rate of Return</u> or <u>Maturity Date</u> applying filter on all available claims in Claim Market browsing the ones that meet the criterion set.</p>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-lg d-flex flex-column justify-content-center px-5 py-3">
                    <div className="icon-box">
                    <div className="icon"><i className="bx bx-message-rounded-detail"></i></div>
                    <h4 className="title">I have bought the claim. What is next?</h4>
                    <p className="description">In a week’ time you will receive an e-mail from the platform that services the claim you have bought. In the email there will be the link to the platform’ website as well as instructions for onboarding process to get access to management of your investments. The onboarding process is straightforward and easy two-minutes procedure.</p>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-lg d-flex flex-column justify-content-center px-5 py-3">
                    <div className="icon-box">
                    <div className="icon"><i className="bx bx-message-rounded-detail"></i></div>
                    <h4 className="title">When and how will I receive distribution?</h4>
                    <p className="description">Distributions are typically made by borrowers through a crowdfunding or P2P platform quarterly or in the end of the term on the Maturity Date. Distributions and Claim Amount are automatically deposited into your user account on the crowdfunding platform that services the claim you have bought. To withdraw the funds from a platforms’ user account you will have to provide your EUR account details, where you would like to receive your funds. You will be notified of distributions and will be able to track your distribution history through a platform’ user account interface.</p>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-lg d-flex flex-column justify-content-center px-5 py-3">
                    <div className="icon-box">
                    <div className="icon"><i className="bx bx-message-rounded-detail"></i></div>
                    <h4 className="title">What happens to my investment if KVOTUM no longer exists?</h4>
                    <p className="description">All claims bought from KVOTUM are serviced by crowdfunding or P2P platforms and represent secured loan of a borrower to a claim owner. Borrower will fulfil his obligations via the intermediation of a platform that services this claim regardless of the existence of KVOTUM. In case KVOTUM files for bankruptcy, all transactions and agreements concluded with KVOTUM will be stored and kept in order and retrievable in accordance with the laws of Estonia. However, no new transactions with KVOTUM will be possible thence.</p>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-lg d-flex flex-column justify-content-center px-5 py-3">
                    <div className="icon-box">
                    <div className="icon"><i className="bx bx-message-rounded-detail"></i></div>
                    <h4 className="title">Where are the available claims coming from?</h4>
                    <p className="description">Kvotum is partnering with reliable and professional crowdfunding platforms, allowing their existing users to offer their existing investment for resale. All claims on Kvotum belong to active projects and are secured by physical equity.</p>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-lg d-flex flex-column justify-content-center px-5 py-3">
                    <div className="icon-box">
                    <div className="icon"><i className="bx bx-message-rounded-detail"></i></div>
                    <h4 className="title">What are my payment options?</h4>
                    <p className="description">We can process transactions with credit card, bank wire and alternative currencies(such as BitCoin or Ethereum and more).</p>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-lg d-flex flex-column justify-content-center px-5 py-3">
                    <div className="icon-box">
                    <div className="icon"><i className="bx bx-message-rounded-detail"></i></div>
                    <h4 className="title">I am afraid for my personal data. How secure is Kvotum?</h4>
                    <p className="description">We do our best to protect our users data, your information is either encrypted, or (in the case of your credit card details) - do not store them at all.</p>
                    </div>
                </div>
                </div>    
                <div className="row">
                <div className="col-lg d-flex flex-column justify-content-center px-5 py-3">
                    <div className="icon-box">
                    <div className="icon"><i className="bx bx-message-rounded-detail"></i></div>
                    <h4 className="title">I am a professional investor/investment firm, and require a large portfolio. Is this possible?</h4>
                    <p className="description">For professional investors - we provide individual tools and services. Get in touch with our support team and we will provide a solution!</p>
                    </div>
                </div>
                </div>         
            </div>
        </section>
      </main>
      <footer id="footer" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
        <FooterComponent />
      </footer>
    </>
  )
}

export default FaqPage